body {
    background-color: black;
    box-sizing: border-box;
}



.iframe-container {
    position: relative;
    width: 100%;
    /* 16:9 aspect ratio */
    overflow: hidden;
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.Top__right {
    display: none;
}

@font-face {
    font-family: 'ethnocentricrg';
    src: url('../fonts/ethnocentricrg.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PinkBlue';
    src: url('../fonts/PinkBlue.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Protos';
    src: url('../fonts/Protos.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@tailwind base;
@tailwind components;
@tailwind utilities;